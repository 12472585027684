import React from 'react';

const CustomAlertTemplate = ({ message, options, style, close }) => {
  return (
    <div
      style={{
        ...style,
        backgroundColor: options.type === 'error' ? '#f56565' : options.type === 'success' ? '#48bb78' : '#4299e1',
        color: 'white',
        padding: '15px',
        borderRadius: '10px',
        width: '400px',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span>{message}</span>
      <button onClick={close} style={{ marginLeft: '10px', fontWeight: 'bold', background: 'transparent', border: 'none', color: 'white' }}>
        X
      </button>
    </div>
  );
};

export default CustomAlertTemplate;
