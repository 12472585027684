import HttpService from './HttpService';

class AuthService extends HttpService {

  login(email, password) {
    return this.post('/auth/login', {
      email,
      password
    })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('user', JSON.stringify(response.data.user));
        }
        return response.data;
      });
  }

  
  getUser() {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  }

  logout() {
    localStorage.removeItem('token');
  }

  register(userData) {
    return this.post('/auth/register', userData);
  }

  isAuthenticated() {
    return !!localStorage.getItem('token');
  }

  forgotPassword(email) {
    return this.post('/auth/forgot-password', { email });
  }

  resetPassword(token, password) {
    return this.post('/auth/reset-password', { token, password }).then((response) => response.data);
  }

}

export default AuthService;